import { BarDatum } from '@nivo/bar';

export enum StatusBootWhats {
  NotFound = 'not_found',
  Closed = 'closed',
  NotConnected = 'not_connected',
  Connected = 'connected',
}

export enum StatusAvailabilityProduct {
  Always = 'always',
  SpecificDays = 'specific_days',
}

export enum ActionBootWhats {
  ReadQrCode,
  Disconnect,
}

export interface IBase {
  id?: string;
}

export interface IAccess {
  type: string;
  permissions: IPermission[];
}

export interface IAddressLocation {
  lat: number;
  lng: number;
}

export interface IAdminAffiliates {
  id: string;
  alias: string;
  fantasy_name: string;
  situation: string;
  order: number;
  placed: number;
  in_preparation: number;
  awaiting_delivery_or_pickup: number;
  canceled: number;
  delivered: number;
  rated: number;
  situation_description: string;
}

export interface IAdminResume {
  order: number;
  placed: number;
  in_preparation: number;
  awaiting_delivery_or_pickup: number;
  canceled: number;
  delivered: number;
  rated: number;
}

export interface IAffiliate extends IBase {
  company_id?: string;
  neighborhood_id: string;
  code?: number;
  cpf_cnpj: string;
  fantasy_name: string;
  about?: string | null;
  alias?: string;
  corporate_name: string;
  phone?: string | null;
  cellphone: string;
  email: string;
  zip_code: string;
  address: string;
  address_number?: string | null;
  allow_delivery: boolean;
  allow_withdrawal: boolean;
  notify_new_order_by_whatsapp: boolean;
  situation?: string;
  local_menu_module_enabled?: boolean;
  cashback_module_enabled?: boolean;
  cashback_percentage?: number;
  cashback_rescue_minimum_value?: number;
  cashback_expiry_days: number;
  cashback_local_rescue_minimum_value?: number;
  cart_observation?: string | null;
  local_menu_observation?: string | null;
  pix_key?: string | null;
  logo_image_url?: string;
  delivery_time_initial: number;
  delivery_time_final: number;
  delivery_rate_value: number;
  withdrawal_time_initial: number;
  withdrawal_time_final: number;
  minimum_order_value: number;
  whatsapp_session_status?: StatusBootWhats;
  state?: IState;
  citie?: ICities | null;
  neighborhood?: INeighborhood | null;
  longitude?: number;
  latitude?: number;
  facebook_pixel_id?: string | null;
  licence_expiration_at?: Date;
  created_at?: Date;
}

export interface IAffiliateDeliveryCities extends IBase {
  affiliate_id: string;
  city_id: string;
  citie_name?: string;
  citie_state?: string;
  city?: ICities | null;
  state?: IState | null;
}

export interface IAffiliatePhones extends IBase {
  affiliate_id: string;
  type: string;
  phone: string | null;
  type_description?: string;
}

export interface IAffiliateInfo extends IBase {
  fantasy_name: string;
  corporate_name: string;
  situation?: string;
  delivery_time_final: number;
  withdrawal_time_final: number;
  address: string;
  address_number?: string | null;
  neighborhood_name?: string;
  citie_name?: string;
  citie_state?: string;
  local_menu_module_enabled: boolean;
  cashback_module_enabled: boolean;
  cashback_percentage?: number;
  whatsapp_session_status?: StatusBootWhats;
  licence_expiration_at?: Date;
}

export interface IAffiliatePaymentMethods extends IBase {
  affiliate_id?: string;
  payment_method_id: string | null;
  active?: boolean;
  payment_method?: IPaymentMethods;
  discount_percentage: number;
  enable_cashback_generate?: boolean;
}

export interface IAffiliateProducts extends IBase {
  affiliate_id?: string;
  product_id: string | null;
  reference_code?: string;
  sale_price: number;
  allow_sale_delivery: boolean;
  allow_sale_local: boolean;
  allow_sale_withdrawal: boolean;
  sale_price_local?: number;
  active: boolean;
  product?: IProduct;
  product_name?: string;
  category_description?: string;
  type_product?: string;
  active_description?: string;
  allow_sale_delivery_desc?: string;
  allow_sale_local_desc?: string;
  allow_sale_withdrawal_desc?: string;
  complement_items_count?: number;
  enable_cashback_generate: boolean;
  availability: StatusAvailabilityProduct;
  sunday_available: boolean;
  monday_available: boolean;
  tuesday_available: boolean;
  wednesday_available: boolean;
  thursday_available: boolean;
  friday_available: boolean;
  saturday_available: boolean;
}

export interface IAffiliateProductsUnified extends IBase {
  product_id?: string | null;
  description?: string;
  name?: string;
  category_id?: string;
  type?: string;
  reference_code?: string;
  sale_price: number;
  frosty?: boolean;
  enable_cashback_generate: boolean;
  active: boolean;
  image_url?: string;
  allow_sale_delivery: boolean;
  allow_sale_local: boolean;
  allow_sale_withdrawal: boolean;
  sale_price_local?: number;
  category?: ICategory;
  product?: IProduct;
  availability: StatusAvailabilityProduct;
  sunday_available: boolean;
  monday_available: boolean;
  tuesday_available: boolean;
  wednesday_available: boolean;
  thursday_available: boolean;
  friday_available: boolean;
  saturday_available: boolean;
}

export interface IAffiliateProductComplements extends IBase {
  affiliate_id?: string;
  affiliate_product_id: string | null;
  complement_item_id: string | null;
  additional_value: number;
  complement_item?: IComplementItems;
  complement?: string;
  category_description?: string;
  product_name?: string;
}

export interface IBalance {
  balance: number;
}

export interface IBootWhats {
  status: StatusBootWhats;
  qr_code?: string | null;
}

export interface ICategory extends IBase {
  company_id?: string;
  description: string;
  active: boolean;
  priority: number;
  order: string;
  order_description?: string;
}

export interface ICashbackInfo extends IBase {
  company_id: string;
  affiliate_id: string;
  customer_id: string;
  order_id?: string;
  origin: string;
  value: number;
  sale_value: number;
  situation: string;
  observation?: string;
  origin_description: string;
  situation_description: string;
  customer: ICustomer;
  order: IOrder;

  customer_name: string;
  customer_cellphone: string;
  customer_cpf_cnpj: string;
  order_number?: number;
}

export interface ICashback extends IBase {
  customer_id: string;
  customer_name?: string | null;
  type: string;
  value: number;
  sale_value: number;
  observation?: string | null;
  cpf_cnpj?: string;
  customer?: ICustomer | null;
  balance: number;
  final: number;
  order_id?: string;
}

export interface ICities extends IBase {
  country_id: string;
  name: string;
  abbreviated_name: string;
  state: string;
  active: boolean;
  ibge_code: string;
  correios_code: string;
}

export interface IComplement extends IBase {
  description: string;
  priority: number;
  minimum_amount: string;
  maximum_amount: string;
  allow_sorting: boolean;
  rule: string;
  active: boolean;
  items_count?: number;
  order: string;
  order_description?: string;
  rule_description?: string;
}

export interface IComplementItems extends IBase {
  complement_id: string | null;
  product_id: string | null;
  full_name?: string;
  complement?: IComplement;
  product?: IProduct;
}

export interface IComplementClone extends IBase {
  origin_product_id: string;
  affiliate_product_id: string;
  type: string;
  product?: IProduct;
}

export interface IConfiguration {
  order_print_model: string;
}

export interface ICountries extends IBase {
  name: string;
  ibge_code: string;
  active: boolean;
}

export interface ICustomer extends IBase {
  code: number;
  cpf_cnpj: string;
  name: string;
  cellphone: string;
  email: string;
  date_birth: Date;
}

export interface ICustomerAddress extends IBase {
  customer_id: string;
  neighborhood_id: string;
  neighborhood: INeighborhood;
  type: string;
  title?: string | null;
  address: string;
  number: string;
  complement: string;
  reference_point: string;
  zip_code: string;
  longitude: number;
  latitude: number;
  type_description: string;
}

export interface IDashboardAllowed {
  allowed_resume: boolean;
  allowed_day_of_week: boolean;
  allowed_recurring_customer: boolean;
  allowed_payment_method: boolean;
}

export interface IDaysOfWeek {
  previous_period: IPeriodDashboard;
  current_period: IPeriodDashboard;
}

export interface IDeliveryAreas {
  id?: string;
  distance: number;
  delivery_fee: number;
  delivery_time: number;
}

export interface IError {
  status: string;
  message: string;
}

export interface IBillingDayOfWeek {
  id: string;
  data: IDataBillingDayOfWeek[];
}

export interface IDataBillingDayOfWeek {
  x: string;
  y: number;
}

export interface IDashboardDaysOfWeek {
  billing: BarDatum[];
  order: BarDatum[];
}

export interface IFiltersDashboard {
  affiliate?: IAffiliate;
  dateInitial: Date;
  dateFinal: Date;
}

export interface IGroupComplements {
  id: string;
  description: string;
}

export interface IInvoices {
  id?: string;
  number?: number;
  affiliate_id: string;
  start_at: Date;
  end_at: Date;
  quantity_orders?: number;
  free_days: number;
  liquid_value?: number;
  situation?: string;
  pix_copy_paste?: string;
  local_menu_module_enabled: boolean;
  cashback_module_enabled: boolean;
  invoice_file?: string;
  maturity_at: Date;
  paid_at?: Date;
  invoice_file_url?: string;
  situation_description?: string;
  affiliate_fantasy_name?: string;
  affiliate?: IAffiliate;
}

export interface IInvoiceQuantity {
  startNumber: number;
  finalNumber: number;
  quantity: number;
}

export interface IItemClone extends IBase {
  origin_complement_id: string;
  complement_id: string;
  type: string;
  complement?: IComplement;
}

export interface ILogin {
  id: string;
  company_id: string;
  type: string;
  type_description: string;
  username: string;
  token: string;
  active: boolean;
}

export interface INeighborhood extends IBase {
  city_id: string;
  name: string;
  abbreviated_name: string;
  active?: boolean;
  correios_code: string;
  city?: ICities;
}

export interface IOrderItemComplement {
  id?: string;
  order_item_id?: string;
  affiliate_product_complem_id: string;
  complement_id: string;
  complement_description: string;
  product_type: string;
  product_name: string;
  origin_sale_price: number;
  origin_additional_value: number;
  quantity: number;
  unitary_value: number;
  liquid_value: number;
}

export interface IOrderItem {
  id?: string;
  order_id?: string;
  affiliate_product_id: string;
  category_description: string;
  product_type?: string;
  product_name?: string;
  complement_value: number;
  origin_sale_price: number;
  quantity: number;
  unitary_value: number;
  gross_value: number;
  liquid_value: number;
  observation?: string;
  complements?: IOrderItemComplement[];
}

export interface IOrder {
  id: string;
  number: number;
  affiliate_id: string;
  company_id?: string;
  customer_id?: string;
  customer_address_id: string;
  payment_method_id: string;
  payment_type: string;
  payment_description: string;
  customer_code: number;
  customer_name: string;
  customer_cpf_cnpj: string;
  customer_cellphone: string;
  customer_date_birth: string;
  address_street: string;
  address_number: string;
  address_complement: string;
  address_reference_point: string;
  address_zip_code: string;
  address_latitude: number;
  address_longitude: number;
  neighborhood_name: string;
  city_name: string;
  city_state: string;
  delivery_way: string;
  rating: number;
  product_value: number;
  delivery_fee_value: number;
  discount_value: number;
  cashback_rescue_value: number;
  payment_discount_percentage: number;
  order_value: number;
  delivery_observation: string;
  situation: string;
  first_order: boolean;
  in_preparation_at: Date | null;
  canceled_at: Date | null;
  available_for_pickup_at: Date | null;
  shipped_for_delivery_at: Date | null;
  delivered_at: Date | null;
  rated_at: Date | null;
  created_at: Date | null;
  updated_at: Date | null;
  delivery_way_description: string;
  situation_description: string;
  time_initial: number;
  time_final: number;
  items: IOrderItem[];
}

export interface IOrderSituation {
  order_id: string;
  situation: string;
  number: number;
  situation_description: string;
}

export interface IPaymentDashboard {
  type: string;
  type_description: string;
  count: number;
}

export interface IDataPaymentDashboard {
  id: string;
  label: string;
  value: number;
}

export interface IPeriod {
  start_date: Date;
  end_date: Date;
}

export interface IPeriodDashboard {
  start_date: Date;
  end_date: Date;
  items: IPeriodDashboardItems[];
}

export interface IPeriodDashboardItems {
  day_of_week: number;
  day_of_week_description: string;
  count: number;
  value: number;
}

export interface IPayment {
  affiliate_id: string;
  payment_methods: IAffiliatePaymentMethods[];
}

export interface IPaymentMethods extends IBase {
  description: string;
  type: string;
  type_description: string;
  active?: boolean;
}

export interface IPermission {
  type: string;
  slug: string;
  value: string;
}

export interface IProduct extends IBase {
  company_id?: string;
  category_id: string | null;
  category_description?: string | undefined;
  category?: ICategory;
  type: string;
  type_description?: string;
  name: string;
  full_name?: string;
  description: string | null;
  active?: boolean;
  frosty: boolean;
  image_url?: string;
}

export interface IRecurringCustomer {
  total: number;
  recurrent: number;
}

export interface IReportTopSellingProducts {
  id: string;
  name: string;
  quantity: number;
  gross_value: number;
  discount_value: number;
  liquid_value: number;
}

export interface IReportOrdersPerDay {
  date: Date;
  count: number;
  product_value: number;
  delivery_fee_value: number;
  discount_value: number;
  order_value: number;
  pm_money_value: number;
  pm_pix_value: number;
  pm_credit_card_value: number;
  pm_debit_card_value: number;
  pm_meal_ticket_value: number;
  pm_other_value: number;
}

export interface IResume {
  order: number;
  delivery: number;
  withdrawal: number;
  canceled: number;
  average_preparation_time: string;
  average_rating: number;
}

export interface IState {
  id: string;
  description: string;
}

export interface IUtility {
  drawerOpen: boolean;
  dialogOpen: boolean;
  affiliateSelOpen: boolean;
  detailOpen: boolean;
  configureOpen: boolean;
  dialogId: string;
  file: File | null;
  refresh: boolean;
}

export interface IUser extends IBase {
  type: string;
  name: string;
  username?: string;
  password?: string | null;
  active: boolean;
}

export interface IUserInfo {
  id: string;
  company_id: string;
  name: string;
  username: string;
  type: string;
  type_description: string;
  show_changelog: boolean;
  active: boolean;
}

export interface IUserAccess {
  user: IUserInfo;
  affiliate: IAffiliateInfo;
  access: IAccess;
  enable_multiple_affiliate: boolean;
  isLogin: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: IError;
}

export interface IView {
  affiliateDeliveryArea: boolean;
  product: boolean;
  category: boolean;
  cashback: boolean;
  customer: boolean;
  complement: boolean;
  affiliate: boolean;
  order: boolean;
  invoice: boolean;
  ordersPerDay: boolean;
  topSellingProducts: boolean;
  openClose: boolean;
}

export interface IZipCode {
  zip_code: string;
  address: string;
  neighborhood: INeighborhood;
  city: ICities;
}

export interface IAffiliateNewOrderSocket {
  id: string;
  affiliate_id: string;
  delivery_way: string;
  number: number;
  customer_name: string;
  order_value: number;
}
