import React from 'react';
import { Avatar, AvatarProps } from '@mui/material';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { StatusAvailabilityProduct } from '../../models';

interface Props extends AvatarProps {
  description: string;
  name: FieldPath<FieldValues>;
  control: Control<FieldValues> | undefined;
  specificDays: StatusAvailabilityProduct;
  disabled?: boolean;
}

export default function AvatarDays({
  description,
  name,
  control,
  specificDays,
  disabled = false,
  ...props
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue
      render={({ field }) => (
        <Avatar
          {...props}
          {...field}
          sx={{
            width: 24,
            height: 24,
            marginTop: 1,
            cursor:
              specificDays === StatusAvailabilityProduct.SpecificDays &&
              !disabled
                ? 'pointer'
                : undefined,
            bgcolor: field.value && !disabled ? 'primary.main' : 'grey.500',
            fontSize: '1rem',
          }}
          {...(specificDays === StatusAvailabilityProduct.SpecificDays && {
            onClick: () => field.onChange(!field.value),
          })}
        >
          {description}
        </Avatar>
      )}
    />
  );
}

AvatarDays.defaultProps = {
  disabled: false,
};
