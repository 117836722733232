import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from '@mui/material';
import AutocompleteAsync from '../../components/AutocompleteAsync';
import TextField from '../../components/TextField';
import { ICategory, IProduct, StatusAvailabilityProduct } from '../../models';
import NumberField from '../../components/NumberField';
import useUtility from '../../hooks/useUtility';
import AvatarDays from '../../components/AvatarDays';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  isSubmitting?: boolean;
  children?: React.ReactNode;
  unified: boolean;
  localMenuModuleEnabled: boolean;
  cashbackModuleEnabled: boolean;
  additional: boolean;
  specificDays: StatusAvailabilityProduct;
  verifyAdditional: (type: string) => boolean;
  verifyProduct: (product: IProduct) => boolean;
}

export default function Form({
  control,
  isSubmitting,
  children,
  unified,
  localMenuModuleEnabled,
  cashbackModuleEnabled,
  additional,
  specificDays,
  verifyAdditional,
  verifyProduct,
}: Props) {
  const { utilitySelector } = useUtility();
  const [isAdditional, setIsAdditional] = useState(additional);

  return (
    <Grid container spacing={2}>
      {!unified ? (
        <Grid item md={12} xs={12}>
          <AutocompleteAsync
            name="product"
            control={control}
            title="Produto"
            required
            url="products"
            {...(utilitySelector.dialogId !== ''
              ? { disabled: true }
              : { autoFocus: !unified })}
            getLabel={(option: IProduct) => option.full_name || ''}
            disabled={utilitySelector.dialogId !== '' || isSubmitting}
            onVerify={verifyProduct}
            beforeChange={(product: IProduct) =>
              setIsAdditional(product.type === 'additional')
            }
          />
        </Grid>
      ) : (
        <>
          <Grid item md={9} xs={12}>
            <Grid item md={12} xs={12}>
              <TextField
                id="name"
                name="name"
                control={control}
                defaultValue=""
                variant="standard"
                fullWidth
                label="Nome do Produto"
                required
                autoFocus={unified}
                InputLabelProps={{ shrink: true }}
                disabled={isSubmitting}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                id="description"
                name="description"
                control={control}
                defaultValue=""
                variant="standard"
                fullWidth
                label="Descrição do Produto"
                InputLabelProps={{ shrink: true }}
                disabled={isSubmitting}
              />
            </Grid>

            <Grid container spacing={2}>
              <Grid item md={9} xs={12}>
                <AutocompleteAsync
                  name="category"
                  control={control}
                  title="Categoria"
                  required
                  url="categories"
                  getLabel={(option: ICategory) => option.description}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
                  <InputLabel htmlFor="type-required" shrink>
                    Tipo do Produto
                  </InputLabel>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue="simple"
                    render={({ field }) => (
                      <Select
                        {...field}
                        sx={{ textAlign: 'left' }}
                        id="type"
                        variant="standard"
                        color="primary"
                        label="Tipo do Produto"
                        onChange={event => {
                          if (!verifyAdditional(event.target.value)) {
                            return;
                          }

                          field.onChange(event);
                          setIsAdditional(event.target.value === 'additional');
                        }}
                        required
                        {...(utilitySelector.dialogId !== '' && {
                          disabled: true,
                        })}
                        value={field.value || 'simple'}
                      >
                        <MenuItem value="additional">
                          <Typography>Adicional</Typography>
                        </MenuItem>
                        <MenuItem value="compound">
                          <Typography>Composto</Typography>
                        </MenuItem>
                        <MenuItem value="simple">
                          <Typography>Simples</Typography>
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} xs={12}>
            {children}
          </Grid>
        </>
      )}

      <Grid item md={6} xs={12}>
        <TextField
          id="reference_code"
          name="reference_code"
          control={control}
          defaultValue=""
          variant="standard"
          fullWidth
          label="Código de Referência"
          InputLabelProps={{ shrink: true }}
          {...(utilitySelector.dialogId !== '' && { autoFocus: !unified })}
          disabled={isSubmitting}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <NumberField
          id="sale_price"
          name="sale_price"
          control={control}
          defaultValue={0}
          variant="standard"
          fullWidth
          label="Preço de Venda"
          required
          decimalScale={2}
          InputLabelProps={{ shrink: true }}
          disabled={isSubmitting}
          startAdornment={
            <InputAdornment position="start">
              <Typography component="span" variant="caption">
                R$
              </Typography>
            </InputAdornment>
          }
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <NumberField
          id="sale_price_local"
          name="sale_price_local"
          control={control}
          defaultValue={0}
          variant="standard"
          fullWidth
          label="Preço Consumo no Local"
          decimalScale={2}
          InputLabelProps={{ shrink: true }}
          disabled={!localMenuModuleEnabled || isSubmitting}
          startAdornment={
            <InputAdornment position="start">
              <Typography component="span" variant="caption">
                R$
              </Typography>
            </InputAdornment>
          }
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <Card sx={{ border: '1px solid #cccccc !important' }}>
          <CardHeader
            sx={{
              padding: '0.3rem 1.0rem 0.3rem 1.0rem',
              bgcolor: '#f9ebeb',
            }}
            title={
              <FormLabel
                id="availability-product"
                sx={{ color: 'text.primary' }}
              >
                Disponibilidade - Aqui você define em quais dias os clientes
                poderão comprar este item
              </FormLabel>
            }
            titleTypographyProps={{ variant: 'body1' }}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Controller
                  name="availability"
                  control={control}
                  defaultValue="always"
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      aria-labelledby="availability-product-group"
                      name="availability"
                      row
                    >
                      <FormControlLabel
                        value="always"
                        control={<Radio disabled={isSubmitting} />}
                        label="Sempre Disponível"
                        title="O item ficará disponível sempre que o restaurante estiver aberto"
                      />
                      <FormControlLabel
                        value="specific_days"
                        control={<Radio disabled={isSubmitting} />}
                        label="Disponível em Dias Específicos"
                        title="Selecione os dias em que o item ficará disponível no aplicativo"
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 2,
                }}
                item
                md={6}
                xs={12}
              >
                <AvatarDays
                  name="sunday_available"
                  control={control}
                  description="D"
                  alt="Domingo"
                  title="Domingo"
                  specificDays={specificDays}
                  disabled={isSubmitting}
                />
                <AvatarDays
                  name="monday_available"
                  control={control}
                  description="S"
                  alt="Segunda-feira"
                  title="Segunda-feira"
                  specificDays={specificDays}
                  disabled={isSubmitting}
                />
                <AvatarDays
                  name="tuesday_available"
                  control={control}
                  description="T"
                  alt="Terça-feira"
                  title="Terça-feira"
                  specificDays={specificDays}
                  disabled={isSubmitting}
                />
                <AvatarDays
                  name="wednesday_available"
                  control={control}
                  description="Q"
                  alt="Quarta-feira"
                  title="Quarta-feira"
                  specificDays={specificDays}
                  disabled={isSubmitting}
                />
                <AvatarDays
                  name="thursday_available"
                  control={control}
                  description="Q"
                  alt="Quinta-feira"
                  title="Quinta-feira"
                  specificDays={specificDays}
                  disabled={isSubmitting}
                />
                <AvatarDays
                  name="friday_available"
                  control={control}
                  description="S"
                  alt="Sexta-feira"
                  title="Sexta-feira"
                  specificDays={specificDays}
                  disabled={isSubmitting}
                />
                <AvatarDays
                  name="saturday_available"
                  control={control}
                  description="S"
                  alt="Sábado"
                  title="Sábado"
                  specificDays={specificDays}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {unified && (
        <Grid item md={cashbackModuleEnabled ? 3 : 2} xs={12}>
          <FormControlLabel
            sx={{ width: '100%' }}
            control={
              <Controller
                name="frosty"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    id="frosty"
                    color="primary"
                    onChange={e => field.onChange(e.target.checked)}
                    checked={field.value || false}
                    disabled={isSubmitting}
                  />
                )}
              />
            }
            label="Gelado(a)"
          />
        </Grid>
      )}

      <Grid item md={3} xs={12}>
        <FormControlLabel
          sx={{ width: '100%', cursor: isAdditional ? 'default' : 'pointer' }}
          control={
            <Controller
              name="allow_sale_local"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="allow_sale_local"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                  disabled={
                    isSubmitting || !localMenuModuleEnabled || isAdditional
                  }
                />
              )}
            />
          }
          label="Permite Consumo no Local"
        />
      </Grid>

      <Grid item md={3} xs={6}>
        <FormControlLabel
          sx={{ width: '100%', cursor: isAdditional ? 'default' : 'pointer' }}
          control={
            <Controller
              name="allow_sale_withdrawal"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="allow_sale_withdrawal"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                  disabled={isSubmitting || isAdditional}
                />
              )}
            />
          }
          label="Permite Retirada"
        />
      </Grid>

      <Grid item md={3} xs={6}>
        <FormControlLabel
          sx={{ width: '100%', cursor: isAdditional ? 'default' : 'pointer' }}
          control={
            <Controller
              name="allow_sale_delivery"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="allow_sale_delivery"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                  disabled={isSubmitting || isAdditional}
                />
              )}
            />
          }
          label="Permite Entrega"
        />
      </Grid>

      <Grid item md={unified ? 3 : 5} xs={12}>
        <FormControlLabel
          sx={{ width: '100%' }}
          control={
            <Controller
              name="enable_cashback_generate"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="enable_cashback_generate"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                  disabled={
                    isSubmitting || !cashbackModuleEnabled || isAdditional
                  }
                />
              )}
            />
          }
          label="Cashback"
        />
      </Grid>

      <Grid item md={unified ? 3 : 5} xs={12}>
        <FormControlLabel
          sx={{ width: '100%' }}
          control={
            <Controller
              name="active"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="active"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                  disabled={isSubmitting}
                />
              )}
            />
          }
          label="Ativo"
        />
      </Grid>
    </Grid>
  );
}

Form.defaultProps = {
  isSubmitting: false,
  children: undefined,
};
