/* eslint-disable camelcase */
/* eslint-disable no-empty */
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import { VisibilityOutlined } from '@mui/icons-material';
import { useMutation } from 'react-query';
import { MUIDataTableColumnDef } from 'mui-datatables';
import ActionsDataTable from '../../components/ActionsDataTable';
import useUtility from '../../hooks/useUtility';
import DataTable from '../../components/DataTable';
import DialogForm from '../../components/DialogForm';
import Page from '../../components/Page';
import Label from '../../components/Label';
import {
  IAffiliateProducts,
  IAffiliateProductsUnified,
  IProduct,
  StatusAvailabilityProduct,
} from '../../models';
import Form from './Form';
import Item from './Item';
import snack from '../../components/SnackbarUtilsConfigurator';
import Title from '../../components/Title';
import {
  getAffiliateProduct,
  getAffiliateProducts,
  useAffiliateProducts,
} from '../../services/hooks/useAffiliateProduct';
import api from '../../services/api';
import { queryClient } from '../../services/queryClient';
import ImagePreview from '../../components/ImagePreview';
import useLogin from '../../hooks/useLogin';
import { useCan } from '../../hooks/useCan';

const schema = yup
  .object()
  .shape({
    product: yup.object().required().nullable(),
    sale_price: yup.number().required(),
    availability: yup.string().required(),
  })
  .required();

const schemaUnified = yup
  .object()
  .shape({
    category: yup.object().required().nullable(),
    type: yup.string().required().nullable(),
    name: yup.string().required().nullable(),
    sale_price: yup.number().required(),
    availability: yup.string().required(),
  })
  .required();

yup.setLocale(ptForm);
yup.setLocale({ mixed: { notType: 'Valor inválido.' } });

export default function Catalog() {
  const { userSelector } = useLogin();
  const { data, isLoading, isFetching } = useAffiliateProducts();
  const [products, setProducts] = useState<IAffiliateProducts[]>();
  const [isLoadingX, setIsLoadingX] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const dispatch = useDispatch();
  const { closeDialog, utilitySelector, setRefresh } = useUtility();
  const [affiliateProducts, setAffiliateProducts] =
    useState<IAffiliateProducts>();
  const [openItems, setOpenItems] = useState(false);
  const { control, handleSubmit, reset, getValues, watch, setValue } =
    useForm<IAffiliateProductsUnified>({
      resolver: yupResolver(
        !userSelector.enable_multiple_affiliate ? schemaUnified : schema
      ),
    });

  const isCreate = useCan({ view: 'product.create' });
  const isUpdate = useCan({ view: 'product.update' });

  const specificDays = watch('availability');

  useEffect(() => {
    if (specificDays === StatusAvailabilityProduct.Always) {
      setValue('sunday_available', true);
      setValue('monday_available', true);
      setValue('tuesday_available', true);
      setValue('wednesday_available', true);
      setValue('thursday_available', true);
      setValue('friday_available', true);
      setValue('saturday_available', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificDays]);

  const formatResponse = (response: IAffiliateProducts, unified: boolean) => {
    if (unified) {
      return {
        id: response.id,
        name: response.product?.name,
        description: response.product?.description,
        category_id: response.product?.category_id,
        type: response.product?.type,
        sale_price: response.sale_price,
        category: response.product?.category,
        product: response.product,
        product_id: response.product?.id,
        frosty: response.product?.frosty,
        active: response.active,
        reference_code: response.reference_code,
        image_url: response.product?.image_url,
        allow_sale_delivery: response.allow_sale_delivery,
        allow_sale_local: response.allow_sale_local,
        allow_sale_withdrawal: response.allow_sale_withdrawal,
        sale_price_local: response.sale_price_local,
        enable_cashback_generate: response.enable_cashback_generate,
        availability: response.availability,
        sunday_available: response.sunday_available,
        monday_available: response.monday_available,
        tuesday_available: response.tuesday_available,
        wednesday_available: response.wednesday_available,
        thursday_available: response.thursday_available,
        friday_available: response.friday_available,
        saturday_available: response.saturday_available,
      } as IAffiliateProductsUnified;
    }

    return {
      id: response.id,
      product_id: response.product_id,
      reference_code: response.reference_code,
      sale_price: response.sale_price,
      allow_sale_delivery: response.allow_sale_delivery,
      allow_sale_local: response.allow_sale_local,
      allow_sale_withdrawal: response.allow_sale_withdrawal,
      sale_price_local: response.sale_price_local,
      active: response.active,
      product: response.product,
      enable_cashback_generate: response.enable_cashback_generate,
      availability: response.availability,
      sunday_available: response.sunday_available,
      monday_available: response.monday_available,
      tuesday_available: response.tuesday_available,
      wednesday_available: response.wednesday_available,
      thursday_available: response.thursday_available,
      friday_available: response.friday_available,
      saturday_available: response.saturday_available,
    } as IAffiliateProductsUnified;
  };

  useEffect(() => {
    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        setImageUrl(undefined);
        setIsLoadingX(true);
        getAffiliateProduct(utilitySelector.dialogId)
          .then(response => {
            const result = formatResponse(
              response,
              !userSelector.enable_multiple_affiliate
            );
            reset(result);

            if (result?.image_url !== '') {
              setImageUrl(result?.image_url);
            }
          })
          .finally(() => setIsLoadingX(false));
      } else {
        setImageUrl(undefined);
        reset({});
      }
    }
  }, [
    utilitySelector.dialogOpen,
    utilitySelector.dialogId,
    reset,
    userSelector.enable_multiple_affiliate,
  ]);

  useEffect(() => {
    setProducts(data);
  }, [data]);

  const refresh = useCallback(async () => {
    setIsLoadingX(true);
    return getAffiliateProducts()
      .then(result => setProducts(result))
      .finally(() => setIsLoadingX(false));
  }, []);

  useEffect(() => {
    if (utilitySelector.refresh) {
      refresh();
      dispatch(setRefresh(false));
    }
  }, [utilitySelector.refresh, dispatch, refresh, setRefresh]);

  const productTemplate = (value: string) => {
    return (
      <Label
        variant="ghost"
        color={
          (value === 'Simples' && 'primary') ||
          (value === 'Composto' && 'info') ||
          'success'
        }
      >
        {value}
      </Label>
    );
  };

  const numberTemplate = (value: string) => {
    return (
      <div>
        {parseFloat(value).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
    );
  };

  const activeTemplate = (value: string) => {
    return (
      <Label variant="ghost" color={(value === 'Não' && 'error') || 'success'}>
        {value}
      </Label>
    );
  };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const mapColumns = (
    affiliateProductsX: IAffiliateProductsUnified,
    unified: boolean
  ): IAffiliateProductsUnified => {
    if (unified) {
      return {
        name: affiliateProductsX.name,
        description: affiliateProductsX.description,
        type: affiliateProductsX.type,
        reference_code: affiliateProductsX.reference_code,
        sale_price: affiliateProductsX.sale_price,
        category_id: affiliateProductsX.category?.id,
        frosty: affiliateProductsX.frosty,
        allow_sale_delivery: affiliateProductsX.allow_sale_delivery,
        allow_sale_local: affiliateProductsX.allow_sale_local,
        allow_sale_withdrawal: affiliateProductsX.allow_sale_withdrawal,
        sale_price_local: affiliateProductsX.sale_price_local,
        enable_cashback_generate: affiliateProductsX.enable_cashback_generate,
        active: affiliateProductsX.active,
        availability: affiliateProductsX.availability,
        sunday_available: affiliateProductsX.sunday_available,
        monday_available: affiliateProductsX.monday_available,
        tuesday_available: affiliateProductsX.tuesday_available,
        wednesday_available: affiliateProductsX.wednesday_available,
        thursday_available: affiliateProductsX.thursday_available,
        friday_available: affiliateProductsX.friday_available,
        saturday_available: affiliateProductsX.saturday_available,
      };
    }

    return {
      product_id: affiliateProductsX.product?.id || null,
      sale_price: affiliateProductsX.sale_price,
      reference_code: affiliateProductsX.reference_code,
      allow_sale_delivery: affiliateProductsX.allow_sale_delivery,
      allow_sale_local: affiliateProductsX.allow_sale_local,
      allow_sale_withdrawal: affiliateProductsX.allow_sale_withdrawal,
      sale_price_local: affiliateProductsX.sale_price_local,
      enable_cashback_generate: affiliateProductsX.enable_cashback_generate,
      active: affiliateProductsX.active,
      availability: affiliateProductsX.availability,
      sunday_available: affiliateProductsX.sunday_available,
      monday_available: affiliateProductsX.monday_available,
      tuesday_available: affiliateProductsX.tuesday_available,
      wednesday_available: affiliateProductsX.wednesday_available,
      thursday_available: affiliateProductsX.thursday_available,
      friday_available: affiliateProductsX.friday_available,
      saturday_available: affiliateProductsX.saturday_available,
    };
  };

  const createOrUpdateAffiliateProduct = useMutation(
    async (affiliateProduct: IAffiliateProductsUnified) => {
      const obj = mapColumns(
        affiliateProduct,
        !userSelector.enable_multiple_affiliate
      );
      const baseUrl = !userSelector.enable_multiple_affiliate
        ? '/affiliate/unified_products'
        : 'affiliate/products';

      if (affiliateProduct.id) {
        const responseX = await api.put<IAffiliateProductsUnified>(
          `${baseUrl}/${affiliateProduct.id}`,
          obj
        );

        return responseX.data;
      }

      const response = await api.post<IAffiliateProductsUnified>(baseUrl, obj);

      return response.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('affiliate_products');
        dispatch(closeDialog());
      },
    }
  );

  const activeAffiliateProduct = useMutation(
    async (affiliateProduct: IAffiliateProducts) => {
      const obj = mapColumns(affiliateProduct, false);

      const responseX = await api.put<IAffiliateProducts>(
        `affiliate/products/${affiliateProduct.id}`,
        obj
      );

      return responseX.data;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries('affiliate_products');
      },
    }
  );

  const request = async (
    obj: IAffiliateProductsUnified,
    execute?: (id: string) => Promise<void>
  ) => {
    setIsSubmitting(true);
    await createOrUpdateAffiliateProduct
      .mutateAsync(obj)
      .then(result => {
        if (execute && result.product?.id) {
          execute(result.product?.id);
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const onSubmit = async (formData: IAffiliateProductsUnified) => {
    const { file } = utilitySelector;
    const urlImageData = getValues('image_url');

    const upload =
      (utilitySelector.dialogId === '' && !file) ||
      (imageUrl && !file) ||
      (!imageUrl && !file && !urlImageData);

    const imageUpload = async (id: string) => {
      if (upload) {
        return;
      }

      const objFile = new FormData();

      if (file) objFile.append('image', file);

      await api.patch(`products/image/${id}`, objFile, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    };

    await request(
      formData,
      !userSelector.enable_multiple_affiliate ? imageUpload : undefined
    );
  };

  const activeAffiliateProducts = async (
    affiliateProductsX: IAffiliateProducts
  ) => {
    affiliateProductsX.active = !affiliateProductsX.active;
    if (affiliateProductsX.id) {
      await activeAffiliateProduct.mutateAsync(affiliateProductsX);
    }
  };

  const openAffiliateProducts = (affiliateProductsX: IAffiliateProducts) => {
    if (affiliateProductsX.product?.type !== 'compound') {
      snack.error(
        'Complementos são permitidos apenas para produtos do tipo composto'
      );
      return;
    }

    setAffiliateProducts(affiliateProductsX);
    setOpenItems(true);
  };

  const returnComplementItemsCount = (dataIndex: number) => {
    if (products) {
      return products[dataIndex]?.complement_items_count
        ?.toString()
        .padStart(3, '0');
    }

    return '000';
  };

  const itemsTemplate = (dataIndex: number) => {
    if (!products) {
      return undefined;
    }

    return (
      <Tooltip title="Items" placement="top-end">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {returnComplementItemsCount(dataIndex)}
          <IconButton
            size="small"
            aria-label="items data"
            onClick={() => openAffiliateProducts(products[dataIndex])}
          >
            <VisibilityOutlined fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>
    );
  };

  const closeItems = () => {
    setOpenItems(false);
  };

  const afterDelete = () => {
    setImageUrl(undefined);
  };

  const centerHeadLabelTemplate = (caption: string) => {
    return <div style={{ textAlign: 'center', marginRight: 9 }}>{caption}</div>;
  };

  const actions = ActionsDataTable(
    true,
    products || [],
    activeAffiliateProducts,
    undefined,
    !isUpdate
  );

  const columnsLocalMenu: MUIDataTableColumnDef[] = [
    {
      name: 'sale_price_local',
      label: 'Preço Cons. Local',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
    {
      name: 'allow_sale_local_desc',
      label: 'Local',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => activeTemplate(value),
      },
    },
  ];

  const columnsFinal: MUIDataTableColumnDef[] = [
    {
      name: 'allow_sale_withdrawal_desc',
      label: 'Retirada',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => activeTemplate(value),
      },
    },
    {
      name: 'allow_sale_delivery_desc',
      label: 'Entrega',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => activeTemplate(value),
      },
    },
    {
      name: 'active_description',
      label: 'Ativo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => activeTemplate(value),
      },
    },
    {
      name: 'id',
      label: 'Items',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: number) => itemsTemplate(dataIndex),
        setCellProps: () =>
          window.innerWidth < 900
            ? { style: {} }
            : { style: { whiteSpace: 'nowrap', width: '3%' } },
        customHeadLabelRender: () => centerHeadLabelTemplate('Itens'),
      },
    },
  ];

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'product_name',
      label: 'Produto',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'type_product',
      label: 'Tipo do Produto',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => productTemplate(value),
      },
    },
    {
      name: 'category_description',
      label: 'Categoria',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'sale_price',
      label: 'Preço Venda',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: number) => numberTemplate(value.toString()),
      },
    },
  ];

  if (userSelector.affiliate.local_menu_module_enabled) {
    columnsLocalMenu.forEach(x => columns.push(x));
  }

  columnsFinal.forEach(x => columns.push(x));

  if (actions) {
    columns.push(actions);
  }

  const verifyAdditional = (type: string) => {
    if (type === 'additional') {
      if (!getValues('allow_sale_local')) {
        snack.error(
          'A flag "Permite Consumo no Local" não pode ser desmarcada para produtos do tipo "Adicional"'
        );
        return false;
      }

      if (!getValues('allow_sale_withdrawal')) {
        snack.error(
          'A flag "Permite Retirada" não pode ser desmarcada para produtos do tipo "Adicional"'
        );
        return false;
      }

      if (!getValues('allow_sale_delivery')) {
        snack.error(
          'A flag "Permite Entrega" não pode ser desmarcada para produtos do tipo "Adicional"'
        );
        return false;
      }

      if (!getValues('enable_cashback_generate')) {
        snack.error(
          'A flag "Cashback" não pode ser desmarcada para produtos do tipo "Adicional"'
        );
        return false;
      }
    }

    return true;
  };

  const verifyProduct = (product: IProduct) => {
    return verifyAdditional(product.type);
  };

  return (
    <Page>
      <DataTable
        title={
          <Title
            description="Catálogos"
            isLoading={isLoading || isFetching || isLoadingX}
          />
        }
        data={products || []}
        columns={columns}
        isLoading={isLoading || isFetching}
        refresh={refresh}
        hideActionAddToolbar={!isCreate}
      />
      <DialogForm
        titleDiag="Catálogo"
        maxForm={!userSelector.enable_multiple_affiliate ? 'lg' : 'sm'}
        open={utilitySelector.dialogOpen}
        isLoading={isLoadingX}
        isSubmitting={isSubmitting}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form
          control={control}
          isSubmitting={isSubmitting}
          unified={!userSelector.enable_multiple_affiliate}
          localMenuModuleEnabled={
            userSelector.affiliate.local_menu_module_enabled
          }
          cashbackModuleEnabled={userSelector.affiliate.cashback_module_enabled}
          additional={
            getValues('product')?.type === 'additional' ||
            getValues('type') === 'additional' ||
            false
          }
          specificDays={specificDays}
          verifyAdditional={verifyAdditional}
          verifyProduct={verifyProduct}
        >
          <ImagePreview
            image={imageUrl}
            isSubmitting={isSubmitting}
            afterDelete={afterDelete}
            sx={{
              height: 144,
              // width: '100%',
              maxHeight: { xs: 233, md: 144 },
              // maxWidth: { xs: 350, md: 250 },
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              cursor: 'pointer',
            }}
          />
        </Form>
      </DialogForm>

      {affiliateProducts && (
        <Item
          affiliateProducts={affiliateProducts}
          open={openItems}
          onClose={closeItems}
          isUpdate={isUpdate}
        />
      )}
    </Page>
  );
}
